import React, { useEffect, useState } from 'react';

export const ThemeContext = React.createContext({
  name: 'light',
  updateTheme: () => { },
});

export const ThemeProvider = ({ children }) => {
  // const melbCoordinates = { x: -37.8136, y: 144.9631 };
  const [theme, setTheme] = useState('light');
  const [todayMorning, setTodayMorning] = useState(new Date());
  const [todayEvening, setTodayEvening] = useState(new Date());

  useEffect(() => {
    setTodayMorning(todayMorning.setHours(6, 30));
    setTodayEvening(todayEvening.setHours(18, 30));
    // const yesterdayEvening = new Date();
    // yesterdayEvening.setDate(todayEvening.getDate() - 1);
    // yesterdayEvening.setHours(18, 30);

    // new Date() < getSunset(melbCoordinates.x, melbCoordinates.y, new Date()) &&
    // new Date() > getSunrise(melbCoordinates.x, melbCoordinates.y, yesterday)

    (new Date() > todayMorning && new Date() < todayEvening)
      ? setTheme('light')
      : (new Date() < todayMorning || new Date() >= todayEvening)
        ? setTheme('dark') : setTheme('dark')
  }, [setTodayMorning, setTodayEvening, setTheme]);

  return (
    <ThemeContext.Provider
      value={{
        name: theme,
        updateTheme: setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
